






























































































































import Vue from 'vue'
import Organization from '../../../../store/models/Organization'

export default Vue.extend({
  data() {
    return {
      loading: false,
      form: {
        name: '',
        representativeName: '',
        address: '',
        email: '',
        contact: '',
        interventionZone: '',
        nbAgency: 0
      }
    }
  },

  computed: {
    sfdId(): string {
      return this.$route.params.sfdId
    },

    sfd(): Organization {
      return Organization.find(this.sfdId) as Organization
    }
  },

  async created() {
    await Organization.api().find(this.sfdId)

    if (this.sfd) {
      this.form.name = this.sfd.name
      this.form.representativeName = this.sfd.representativeName
      this.form.address = this.sfd.address
      this.form.email = this.sfd.email
      this.form.contact = this.sfd.contact
      this.form.interventionZone = this.sfd.interventionZone
      this.form.nbAgency = this.sfd.nbAgency
    }
  },

  methods: {
    onSubmit(this: any) {
      this.loading = true
      Organization.api()
        .update(this.sfdId, {
          ...this.form,
          contact: this.form.contact.replace(/\s/g, '')
        })
        .then(() => {
          this.loading = false
          this.$router.push({ name: 'sfds.index' })
          this.$notify.success('Le SFD a été mise à jour avec succès')
        })
        .catch((error: any) => {
          // TODO: handle update errors
          this.loading = false
          console.log(error) // eslint-disable-line
        })
    }
  }
})
